(function () {
    'use strict';

    var createAbsence = {
        templateUrl: 'app/components/custom-components/create-absence/create-absence.template.html',
        controller: 'CreateAbsenceController',
        controllerAs: 'vm',
        restrict: 'E',
        scope: {
            requestPanelOpen: '=requestPanelOpen',
            currentEmployee: '=currentEmployee'
        }
    };

    angular
        .module('holidayApp')
        .component('createAbsence', createAbsence);

})();
