(function() {
    'use strict';

    angular
        .module('holidayApp')
        .filter('filterRequests', filterRequests);

    function filterRequests() {
        return requestsFilter;

        function requestsFilter (absence, userName, years) {
            var filteredByName = [];

            if(!userName || userName === ""){
                filteredByName = absence;
            }else {
                for (var i = 0; i < absence.length; i++) {
                    var name = absence[i].employee.user.fullName;
                    name = name.toLowerCase();
                    userName = userName.toLowerCase();
                    if (name.indexOf(userName) !== -1) {
                        filteredByName.push(absence[i]);
                    }
                }
            }


            var filteredByDate = [];
            for(var i = 0; i < years.length; i++){
                if(years[i] !== ""){
                    console.log(years[i]);
                    for(var k = 0; k < filteredByName.length; k++){
                        if((filteredByName[k].fromDate.substring(0,4) === years[i] || filteredByName[k].toDate.substring(0,4) === years[i]) &&  !filteredByDate.includes(filteredByName[k])){
                            console.log(filteredByName[k])
                            filteredByDate.push(filteredByName[k]);
                        }
                    }
                }

            }

            return filteredByDate;

        }
    }
})();
