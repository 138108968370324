(function () {
    'use strict';
    angular
        .module('holidayApp')
        .factory('BusinessDays', BusinessDays);

    BusinessDays.$inject = ['$resource', 'DateUtils'];

    function BusinessDays($resource, DateUtils) {
        var resourceUrl = 'api/absences/businessDays';

        return $resource(resourceUrl, {}, {
            'getRequestedDays': {
                method: 'GET',
                isArray: false,
                params: { fromDate: "@fromDate",
                            toDate: "@toDate"
                },
                transformResponse: function(data){
                    if (data) {
                        data = angular.fromJson(data);
                        data.fromDate = DateUtils.convertLocalDateFromServer(data.fromDate);
                        data.toDate = DateUtils.convertLocalDateFromServer(data.toDate);
                    }
                    return data;
                }
            }
        });
    }
})();
