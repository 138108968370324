(function () {
    'use strict';


    angular
        .module('holidayApp')
        .controller('DetailAbsenceController', DetailAbsenceController);


    DetailAbsenceController.$inject = ['$scope', '$attrs', 'Absence'];


    function DetailAbsenceController($scope, $attrs, Absence) {
        var vm = this;
        vm.id = -1;
        vm.absenceDetails = {};
        vm.isCollapsedDetail = true;

        $attrs.$observe('detailPanelOpen', function (value) {
            if (vm.isCollapsedDetail) {
                vm.isCollapsedDetail =
                    (value === "true");
            } else {
                vm.isCollapsedDetail = true;
            }
        });




        $attrs.$observe('absenceId', function (value) {
            vm.id = value;
            init();
        });


        function init() {
            if (vm.id > 0) {
                Absence.get({id: vm.id}, function (result) {
                    vm.absenceDetails = result;
                });
            }

        }

    }
})();
