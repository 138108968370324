(function() {
    'use strict';
    angular
        .module('holidayApp')
        .factory('VacationDays', VacationDays);

    VacationDays.$inject = ['$resource'];

    function VacationDays ($resource) {
        var resourceUrl =  'api/employees/addVacationDays';

        return $resource(resourceUrl, {}, {
            'addVacationDays': { method:'POST' }
        });
    }
})();
