(function() {
    "use strict";
    angular.module("holidayApp").factory("ExtraHoliday", ExtraHoliday);

    ExtraHoliday.$inject = ["$resource", "DateUtils"];

    function ExtraHoliday($resource, DateUtils) {
        var resourceUrl = "api/extra-holidays/:id";

        return $resource(
            resourceUrl,
            {},
            {
                query: {
                    method: "GET",
                    isArray: true
                },
                get: { method: "GET" },
                update: {
                    method: "PUT",
                    isArray: true
                },
                save: { method: "POST" }
            }
        );
    }
})();
