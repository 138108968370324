(function () {
    'use strict';

    angular
        .module('holidayApp')
        .controller('ManagementController', ManagementController);


    ManagementController.$inject = ['$state', '$scope', 'entity', 'User', 'Employee', 'Department', 'UserUtils'];


    function ManagementController($state, $scope, entity, User, Employee, Department, UserUtils) {
        var vm = this;

        vm.loaded = false;

        vm.authorities = ['ROLE_USER', 'ROLE_MANAGER', 'ROLE_ADMIN'];
        vm.clear = clear;
        vm.languages = null;
        vm.departments = null;
        vm.saveEmployee = saveEmployee;
        vm.saveDepartment = saveDepartment;
        vm.loadAll = loadAll;
        vm.users = entity;
        vm.department = null;
        vm.employees = [];

        vm.employee = entity;

        vm.role_user = ["ROLE_USER"];
        vm.role_manager = ["ROLE_USER", "ROLE_MANAGER"];
        vm.role_admin = ["ROLE_USER", "ROLE_MANAGER", "ROLE_ADMIN"];


        $scope.isCollapsedNewEmployee = true;
        $scope.isCollapsedNewDepartment = true;


        vm.getInitials = getInitials;
        vm.getInitialsForDepartment = getInitialsForDepartment;
        vm.autogenerateLogin = autogenerateLogin;
        vm.previousAutoLogin = null;

        function getInitialsForDepartment(dep) {
            return dep.substring(0, 2);
        }


        function getInitials(firstName, lastName) {
            var initialFistName = firstName.substring(0, 1);
            var initialLastName = lastName.substring(0, 1);
            return (initialFistName + initialLastName);
        }


        loadAll();

        function loadAll() {
            vm.departments = Department.query();

            vm.users = User.query();
            Employee.query(function (result) {
                vm.employees = result;
                loadDepartmentEmployees();

            });

        }

        function doesLoginAlreadyExist(login) {
            return vm.users.find(function(user) {
                return user.login === login;
            });
        }

        function autogenerateLogin() {

            // check if we are allowed to use a automated username
            if($scope.editEmployeeForm.login.$pristine) {

                // replace umlaute and set everything to lowercase
                var firstName = UserUtils.preprocessLoginName(vm.employee.user.firstName);
                var lastName = UserUtils.preprocessLoginName(vm.employee.user.lastName);
                var login = "".concat(firstName, ".", lastName);

                // now check the given users if the username does already exist and may count the firstName up
                var counter = 1;
                while (doesLoginAlreadyExist(login)) {
                    login = "".concat(firstName, counter, ".", lastName);
                    counter += 1;
                }

                // now set the autogenerated username
                vm.employee.user.login = login;
            }
        }

        function loadDepartmentEmployees() {
            for (var k = 0; k < vm.departments.length; k++) {
                vm.departments[k].count = 0;
                for (var i = 0; i < vm.employees.length; i++) {
                    if (vm.employees[i].department != null) {
                        if (vm.employees[i].department.id == vm.departments[k].id) {
                            vm.departments[k].count += 1;
                        }
                    }
                }
            }
            vm.loaded = true;
        }


        function clear() {
        }

        function onSaveSuccess(result) {
            $state.reload();
        }

        function onSaveError() {

        }

        function saveEmployee() {

            // we need to replace the login umlaut
            vm.employee.user.login = UserUtils.preprocessLoginName(vm.employee.user.login);

            // now save it
            if (vm.employee.user.id !== null) {
                Employee.update(vm.employee, onSaveSuccess, onSaveError);
            } else {
                Employee.save(vm.employee, onSaveSuccess, onSaveError);
            }

        }

        function saveDepartment() {

            if (vm.department.id !== null) {
                Department.update(vm.department, onSaveSuccess);
            } else {
                Department.save(vm.department, onSaveSuccess, onSaveError);
            }
        }

    }
})();
