(function () {
    'use strict';

    angular
        .module('holidayApp')
        .controller('RegisterController', RegisterController);


    RegisterController.$inject = ['$state', '$translate', '$timeout', 'Auth'];

    function RegisterController($state, $translate, $timeout, Auth) {
        var vm = this;

        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.register = register;
        vm.registerAccount = {};
        vm.company = {};
        vm.success = null;

        vm.pagination = 0;
        vm.gatherCompanyData = gatherCompanyData;
        vm.gatherUserData = gatherUserData;
        vm.prevPage = prevPage;
        vm.backToHome = backToHome;

        $timeout(function () {
            angular.element('#login').focus();
        });

        function backToHome(){
            $state.go("home");
        }

        function prevPage() {
            if (vm.pagination > 0) {
                vm.pagination -= 1;
            }

        }


        function gatherCompanyData() {
            vm.pagination += 1;
        }


        function gatherUserData() {
            vm.pagination += 1;


        }

        function register() {
            if (vm.pagination < 2) {
                vm.pagination += 1;
            } else {
                if (vm.company.employee.user.password !== vm.confirmPassword) {
                    vm.doNotMatch = 'ERROR';
                    vm.pagination = 0;
                } else {
                    vm.registerAccount.langKey = $translate.use();
                    vm.doNotMatch = null;
                    vm.error = null;
                    vm.errorUserExists = null;
                    vm.errorEmailExists = null;


                    Auth.createAccount(vm.company).then(function () {
                        vm.success = 'OK';
                        window.setTimeout(backToHome,2500);

                    }).catch(function (response) {
                        vm.success = null;
                        if (response.status === 400 && response.data === 'login already in use') {
                            vm.pagination = 0;
                            vm.errorUserExists = 'ERROR';
                        } else if (response.status === 400 && response.data === 'e-mail address already in use') {
                            vm.pagination = 0;
                            vm.errorEmailExists = 'ERROR';
                        } else {
                            vm.error = 'ERROR';
                        }
                    });
                }
            }
        }
    }
})();
