(function () {
    'use strict';

    var detailAbsence = {
        templateUrl: 'app/components/custom-components/detail-absence/detail-absence.template.html',
        controller: 'DetailAbsenceController',
        controllerAs: 'vm',
        restrict: 'E',
        scope: {
            absenceId: '=absenceId',
            detailPanelOpen: '=detailPanelOpen'
        }
    };

    angular
        .module('holidayApp')
        .component('detailAbsence', detailAbsence);

})();
