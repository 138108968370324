(function() {
    'use strict';

    angular
        .module('holidayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('calendar', {
                parent: 'home',
                url: 'calendar',
                data: {
                    authorities: [],
                    pageTitle: 'holidayApp.dashboard.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/pages/timeline/timeline.html',
                        controller: 'CalendarController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('calendar');
                        $translatePartialLoader.addPart('absence');
                        $translatePartialLoader.addPart('absenceType');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            comment: null,
                            fromDate: null,
                            toDate: null,
                            type: null,
                            id: null
                        };
                    }
                }
            });

    }
})();
