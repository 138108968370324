(function () {
    'use strict';

    angular
        .module('holidayApp')
        .controller('ExportAbsencesDialogController', ExportAbsencesDialogController);

    ExportAbsencesDialogController.$inject = ['$uibModalInstance', '$stateParams'];

    function ExportAbsencesDialogController($uibModalInstance, $stateParams) {
        var vm = this;

        vm.clear = clear;
        vm.exportAbsences = exportAbsences;
        vm.getHeader = getHeader;
        vm.absencesToExport = $stateParams.absencesToExport;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function exportAbsences() {
            $uibModalInstance.dismiss('cancel');
        }


        function getHeader() {
            return ["Id", "FirstName", "LastName", "AvailableVacationDaysThisYear", "UnusedVacationDaysLastYear", "UsedVacationDaysThisYear", "Absences"];
        }
    }
})();
