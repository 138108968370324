(function () {
    'use strict';

    angular
        .module('holidayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('dashboard', {
                parent: 'home',
                url: 'dashboard',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'holidayApp.dashboard.home.title',
                    redirectTo: 'home'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/pages/dashboard/dashboard.html',
                        controller: 'DashboardController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('dashboard');
                        $translatePartialLoader.addPart('absence');
                        $translatePartialLoader.addPart('absenceType');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('error');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            comment: null,
                            fromDate: null,
                            toDate: null,
                            id: null
                        };
                    }
                }
            })
            .state('absence.deleteFromDashboard', {
                parent: 'dashboard',
                url: '/delete/absence/{id}',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/absence/absence-delete-dialog.html',
                        controller: 'AbsenceDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Absence', function (Absence) {
                                return Absence.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('dashboard', null, {reload: 'dashboard'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('absence.editFromDashboard', {
                parent: 'dashboard',
                url: '/edit/absence/{id}',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/absence/absence-dialog.html',
                        controller: 'AbsenceDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Absence', function (Absence) {
                                return Absence.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('dashboard', null, {reload: 'dashboard'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }
})();
