(function() {
    "use strict";

    angular
        .module("holidayApp")
        .controller(
            "AbsenceReasonUpdateDialogController",
            AbsenceReasonUpdateDialogController
        );

    AbsenceReasonUpdateDialogController.$inject = [
        "$timeout",
        "$scope",
        "$stateParams",
        "$uibModalInstance",
        "entity",
        "AbsenceReasons"
    ];

    function AbsenceReasonUpdateDialogController(
        $timeout,
        $scope,
        $stateParams,
        $uibModalInstance,
        entity,
        AbsenceReasons
    ) {
        var vm = this;

        vm.reason = entity;
        vm.clear = clear;
        vm.updateReason = updateReason;

        $("#pick-items-from-dialog")
            .iconpicker({
                icons: [
                    "fa-thermometer-half",
                    "fa-asterisk",
                    "fa-bicycle",
                    "fa-calendar",
                    "fa-car",
                    "fa-coffee",
                    "fa-child",
                    "fa-compass",
                    "fa-female",
                    "fa-globe",
                    "fa-hotel",
                    "fa-heart",
                    "fa-power-off",
                    "fa-question",
                    "fa-snowflake-o",
                    "fa-suitcase",
                    "fa-university",
                    "fa-wheelchair",
                    "fa-rocket",
                    "fa-plane",
                    "fa-ambulance",
                    "fa-train",
                    "fa-truck",
                    "fa-stethoscope",
                    "fa-hospital-o",
                    "fa-medkit",
                    "fa-user-md",
                    "fa-warning"
                ],
                templates: {
                    iconpickerItem:
                        '<a role="button" href="#" class="iconpicker-item" title=""><i></i></a>'
                }
            })
            .next()
            .appendTo("body");
        $(".iconpicker-item").removeAttr("title");

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

        function updateReason() {
            var icon = document.getElementById("selected-icon");
            vm.reason.icon = icon.getAttribute("class").split(" ")[1];
            if (vm.reason.id !== null) {
                AbsenceReasons.update(vm.reason, onSaveSuccess, onSaveError);
            } else {
                AbsenceReasons.save(vm.reason, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit("holidayApp:absenceReasonUpdated", result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
