(function() {
    'use strict';

    angular
        .module('holidayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('requests', {
                parent: 'home',
                url: 'requests',
                data: {
                    authorities: ['ROLE_MANAGER'],
                    pageTitle: 'holidayApp.dashboard.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/pages/requests/requests.html',
                        controller: 'RequestController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('approval');
                        $translatePartialLoader.addPart('approvalStatus');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('calendar');
                        $translatePartialLoader.addPart('absence');
                        $translatePartialLoader.addPart('department');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            comment: null,
                            fromDate: null,
                            toDate: null,
                            type: null,
                            id: null
                        };
                    }
                }
            })
            .state('approval.reject', {
                parent: 'requests',
                url: '/reject/{id}',
                data: {
                    authorities: ['ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/pages/requests/requests-reject-dialog.html',
                        controller: 'ApprovalRejectDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Approval', function(Approval) {
                                return Approval.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('requests', null, { reload: 'requests' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('approval.accept', {
                parent: 'requests',
                url: '/accept/{id}',
                data: {
                    authorities: ['ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/pages/requests/requests-accept-dialog.html',
                        controller: 'ApprovalAcceptDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Approval', function(Approval) {
                                return Approval.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('requests', null, { reload: 'requests' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('absence.deleteFromRequests', {
                parent: 'requests',
                url: '/delete/absence/{id}',
                data: {
                    authorities: ['ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/absence/absence-delete-dialog.html',
                        controller: 'AbsenceDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Absence', function (Absence) {
                                return Absence.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('requests', null, {reload: 'requests'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
