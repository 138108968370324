(function() {
    'use strict';

    angular
        .module('holidayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('employees', {
                parent: 'home',
                url: 'management',
                data: {
                    authorities: ['ROLE_MANAGER'],
                    pageTitle: 'holidayApp.dashboard.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/pages/management/management.html',
                        controller: 'ManagementController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function () {
                        return {
                            availableVacationDaysThisYear: null,
                            unusedVacationDaysLastYear: null,
                            summandFixValue: null,
                            usedVacationDaysThisYear: null,
                            department: null,
                            user : {
                            id: null, login: null, firstName: null, lastName: null, email: null,
                            activated: true, langKey: null, createdBy: null, createdDate: null,
                            lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                            resetKey: null, authorities: null
                            }
                        };
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('user-management');
                        $translatePartialLoader.addPart('employee');
                        $translatePartialLoader.addPart('department');
                        $translatePartialLoader.addPart('error');

                        return $translate.refresh();
                    }]

                }
            })
            .state('employee.deleteEmployee', {
                parent: 'employees',
                url: '/delete/employee/{id}',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/employee/employee-delete-dialog.html',
                        controller: 'EmployeeDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Employee', function(Employee) {
                                return Employee.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('employees', null, { reload: 'employees' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('employee.editEmployee', {
                parent: 'employees',
                url: '/edit/employee/{id}',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/employee/employee-dialog.html',
                        controller: 'EmployeeDialogController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Employee', function(Employee) {
                                return Employee.get({id : $stateParams.id});

                            }]
                        }
                    }).result.then(function() {
                        $state.go('employees', null, { reload: 'employees' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('department.editDepartment', {
                parent: 'employees',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/department/department-dialog.html',
                        controller: 'DepartmentDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Department', function(Department) {
                                return Department.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('employees', null, { reload: 'employees' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('department.deleteDepartment', {
                parent: 'employees',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/department/department-delete-dialog.html',
                        controller: 'DepartmentDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Department', function(Department) {
                                return Department.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('employees', null, { reload: 'employees' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });



    }
})();
