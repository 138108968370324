(function () {
    'use strict';

    angular
        .module('holidayApp')
        .controller('AbsenceDialogController', AbsenceDialogController);

    AbsenceDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity', 'Absence', 'Employee', 'AbsenceReasons'];

    function AbsenceDialogController($timeout, $scope, $uibModalInstance, entity, Absence, Employee, AbsenceReasons) {
        var vm = this;

        vm.absence = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.reasons = AbsenceReasons.query();
        vm.employees = Employee.query();

        vm.checkAndOpenOrClose = checkAndOpenOrClose;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.absence.id !== null) {
                Absence.update(vm.absence, onSaveSuccess, onSaveError);
            } else {
                Absence.save(vm.absence, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('holidayApp:absenceUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


        function checkAndOpenOrClose(date) {
            if (!vm.datePickerOpenStatus[date]) {
                openCalendar(date);
            } else {
                closeCalendar(date);
            }
        }

        vm.datePickerOpenStatus.fromDate = false;
        vm.datePickerOpenStatus.toDate = false;

        function closeCalendar(date) {
            vm.datePickerOpenStatus[date] = false;
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
