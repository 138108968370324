(function () {
    'use strict';

    angular
        .module('holidayApp')
        .controller('CalendarController', CalendarController);


    CalendarController.$inject = ['$scope', '$state', '$filter', '$stateParams', 'entity', 'Absence', 'Employee', 'Principal'];


    function CalendarController($scope, $state, $filter, $stateParams, entity, Absence, Employee, Principal) {
        var vm = this;

        vm.loaded = false;

        vm.settingsAccount = null;
        vm.loadAbsences = loadAbsences;

        vm.acceptedAbsencesInPeriod = null;
        vm.employees = null;
        vm.employeesAbsences = [];
        vm.month = 1;
        vm.holiday = null;
        vm.search = search;
        vm.todayAbsent = 0;
        vm.weekAbsent = 0;
        vm.monthAbsent = 0;
        vm.getInitials = getInitials;

        vm.months = [];
        var monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        vm.currEmployee = null;

        vm.months = getMonths();


        function loadCurrentUser() {
            Employee.get({login: vm.settingsAccount.login}, function (result) {
                vm.currEmployee = result;
                loadAbsences();
            });
        }


        function getInitials(firstName, lastName) {
            var initialFistName = firstName.substring(0, 1);
            var initialLastName = lastName.substring(0, 1);
            return (initialFistName + initialLastName);
        }


        function getAbsentInfo() {
            var todaysDate = moment().format("YYYY-MM-DD"),
                lastSunday = moment().day(0).format("YYYY-MM-DD"),
                nextSunday = moment().day(7).format("YYYY-MM-DD"),
                startMonth = moment().startOf('month').format("YYYY-MM-DD"),
                endMonth = moment().endOf('month').format("YYYY-MM-DD");


            for (var i = 0; i < vm.acceptedAbsencesInPeriod.length; i++) {
                var absFromDate = moment(vm.acceptedAbsencesInPeriod[i].fromDate).format("YYYY-MM-DD");
                var absToDate = moment(vm.acceptedAbsencesInPeriod[i].toDate).format("YYYY-MM-DD");
                if (absFromDate <= todaysDate && absToDate >= todaysDate) {

                    vm.todayAbsent += 1;
                }

                if (absFromDate >= lastSunday && absFromDate <= nextSunday ||
                    absToDate >= lastSunday && absToDate <= nextSunday) {
                    vm.weekAbsent += 1;
                }

                if (absFromDate >= startMonth && absFromDate <= endMonth ||
                    absToDate >= startMonth && absToDate <= endMonth) {
                    vm.monthAbsent += 1;
                }


            }
        }


        function getMonths() {
            var currMonth = new Date().getMonth();
            var months = [];
            for (var i = currMonth - 1; i < currMonth + 13; i++) {
                var month = i;
                if (i == -1) {
                    month = 11;
                } else if (i > 11) {
                    month = i - 12;
                }
                months.push(monthNames[month]);
            }
            return months;
        }

        function loadAbsences() {
            Absence.query({holiday: 1}, function (result) {
                vm.holiday = result;
                Absence.query({only: "ACCEPTED", departmentId: vm.currEmployee.department.id}, function (result) {
                    vm.acceptedAbsencesInPeriod = result;
                    getAbsentInfo();
                    Employee.query({departmentId: vm.currEmployee.department.id}, function (result) {
                        vm.employees = result;
                        getTimelineData();
                    });
                });
            });

        }


        function getTimelineData() {
            for (var i = 0; i < vm.employees.length; i++) {
                vm.employeesAbsences[i] = [];
                vm.employeesAbsences[i].id = vm.employees[i].id;
                vm.employeesAbsences[i].firstName = vm.employees[i].user.firstName;
                vm.employeesAbsences[i].lastName = vm.employees[i].user.lastName;
                vm.employeesAbsences[i].absences = getAbsencesForEmployee(vm.employees[i].id);
                vm.employeesAbsences[i].calendar = getEmployeeCalendar(vm.employeesAbsences[i].absences);
                vm.employeesAbsences[i].color = "#" + intToRGB(hashCode(vm.employees[i].user.lastName));
            }

            vm.employeesAbsences = $filter('orderBy')(vm.employeesAbsences, 'lastName');

            console.log(vm.employeesAbsences);
            vm.loaded = true;

        }

        function hashCode(str) {
            var hash = 0;
            for (var i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }
            return hash;
        }

        function intToRGB(i){
            var c = (i & 0x00FFFFFF)
                .toString(16)
                .toUpperCase();

            return "00000".substring(0, 6 - c.length) + c;
        }


        function getAbsencesForEmployee(id) {
            var abs = [];
            for (var i = 0; i < vm.acceptedAbsencesInPeriod.length; i++) {
                if (vm.acceptedAbsencesInPeriod[i].employee.id == id) {
                    abs.push(vm.acceptedAbsencesInPeriod[i]);
                }
            }
            return abs;
        }

        function getEmployeeCalendar(absences) {
            var count = 0;
            var calendarDate = {};
            var year = new Date().getFullYear();
            for (var i = 0; i < 12; i++) {
                var month = new Date().getMonth() + i;
                calendarDate[i] = [];
                var daysInMonth = new Date(year, month, 0).getDate();
                for (var k = 1; k < daysInMonth + 1; k++) {
                    calendarDate[i][k] = [];
                    var holiday = "";
                    var whichHoliday = "";
                    var date = new Date(year, month - 1, k);
                    for (var z = 0; z < vm.holiday.length; z++) {
                        if (date.toDateString() == new Date(vm.holiday[z].date).toDateString()) {
                            holiday = "feiertag";
                            whichHoliday = vm.holiday[z].description;
                        }
                    }
                    var abs = checkStatusAndId(absences, date);
                    var today = "";
                    var weekend = "";
                    if (count == 0 && new Date().setHours(0, 0, 0, 0) == date.setHours(0, 0, 0, 0)) {
                        today = "today";
                        count++;
                    }
                    if (date.getDay() == 6 || date.getDay() == 0) {
                        weekend = "weekend";
                    }

                    calendarDate[i][k] = {
                        'day': k,
                        'status': abs[0],
                        'absenceid': abs[1],
                        'today': today,
                        'weekend': weekend,
                        'holiday': holiday,
                        'whichHoliday': whichHoliday
                    };

                }
            }

            return calendarDate;
        }


        function checkStatusAndId(absences, date) {
            date = new Date($filter('date')(date, "yyyy-MM-dd"));
            for (var i = 0; i < absences.length; i++) {
                var absenceFromDate = new Date(absences[i].fromDate);
                var absenceToDate = new Date(absences[i].toDate);
                if (date >= absenceFromDate && date <= absenceToDate) {
                    var status = absences[i].approval.status;

                    var id = absences[i].id;
                    if (date.getTime() == absenceFromDate.getTime()) {
                        status += " start";
                    }
                    if (date.getTime() == absenceToDate.getTime()) {
                        status += " end";
                    }
                    var id = absences[i].id;
                    return [status, id];
                }
            }
            return ["none", "null"]
        }


        function search(entity) {

            if (!vm.query || (entity.firstName.toLowerCase().indexOf(vm.query) != -1) ||
                (entity.lastName.toLowerCase().indexOf(vm.query.toLowerCase()) != -1)) {
                return true;
            }
            return false;

        }

        Principal.identity().then(function (account) {
            vm.settingsAccount = copyAccount(account);
            loadCurrentUser();
        });

        /**
         * Store the "settings account" in a separate variable, and not in the shared "account" variable.
         */
        var copyAccount = function (account) {
            return {
                activated: account.activated,
                email: account.email,
                firstName: account.firstName,
                langKey: account.langKey,
                lastName: account.lastName,
                login: account.login
            };
        };


    }
})();
