(function() {
    'use strict';

    angular
        .module('holidayApp')
        .controller('VacationDaysDialogController',VacationDaysDialogController);

    VacationDaysDialogController.$inject = ['$uibModalInstance', 'VacationDays'];

    function VacationDaysDialogController($uibModalInstance, VacationDays) {
        var vm = this;

        vm.clear = clear;
        vm.confirmForm = confirmForm;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmForm () {
            VacationDays.addVacationDays(
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
