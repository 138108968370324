(function() {
    "use strict";
    angular.module("holidayApp").factory("AbsenceReasons", AbsenceReasons);

    AbsenceReasons.$inject = ["$resource"];

    function AbsenceReasons($resource) {
        var resourceUrl = "api/absenceReasons/:id";

        return $resource(
            resourceUrl,
            {},
            {
                query: { method: "GET", isArray: true },
                get: {
                    method: "GET",
                    transformResponse: function(data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                },
                update: { method: "PUT" },
                save: {
                    method: "POST",
                    transformRequest: function(data) {
                        var copy = angular.copy(data);
                        return angular.toJson(copy);
                    }
                }
            }
        );
    }
})();
