(function() {
    'use strict';

    angular
        .module('holidayApp')
        .controller('EmployeeDialogController', EmployeeDialogController);

    EmployeeDialogController.$inject = ['$timeout', '$scope', '$state', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Employee', 'Department', 'Principal', 'Auth'];

    function EmployeeDialogController ($timeout, $scope, $state, $stateParams, $uibModalInstance, $q, entity, Employee, Department, Principal, Auth) {
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_MANAGER', 'ROLE_ADMIN'];

        vm.employee = entity;
        vm.clear = clear;
        vm.save = save;
        vm.departments = Department.query();
        vm.user = null;


        vm.role_user = ["ROLE_USER"];
        vm.role_manager = ["ROLE_USER", "ROLE_MANAGER"];
        vm.role_admin = ["ROLE_USER", "ROLE_MANAGER", "ROLE_ADMIN"];
        vm.checkAuthorities = checkAuthorities;
        vm.loadLoginBeforeChange = loadLoginBeforeChange;

        loadLoginBeforeChange();

        function loadLoginBeforeChange() {
            Employee.get({id: $stateParams.id}, function (result) {
                vm.loginBeforeChange = result.user.login;
            });
        }


        function checkAuthorities() {
            if(vm.employee.user.authorities[0] instanceof Object) {
                var auth = vm.employee.user.authorities;
                vm.employee.user.authorities = [];
                for (var i = 0; i < auth.length; i++) {
                    vm.employee.user.authorities.push(auth[i].name);
                }
            }
        }

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            checkAuthorities();

            vm.isSaving = true;
            if (vm.employee.id !== null) {
                Employee.update(vm.employee, onSaveSuccess, onSaveError);
            } else {
                Employee.save(vm.employee, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('holidayApp:employeeUpdate', result);

            Principal.identity().then(function(account) {

                // logout the admin if he changed its own username
                if(vm.loginBeforeChange === account.login && vm.employee.user.login !== account.login) {
                    Auth.logout();
                    $state.go('home');
                }
            });

            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            console.log(vm.employee);
            vm.isSaving = false;
        }
    }
})();
