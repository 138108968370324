(function() {
    "use strict";

    angular.module("holidayApp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider
            .state("offtimesettings", {
                parent: "home",
                url: "settings",
                data: {
                    authorities: ["ROLE_MANAGER"],
                    pageTitle: "holidayApp.dashboard.home.title"
                },
                views: {
                    "content@": {
                        templateUrl: "app/pages/settings/settings.html",
                        controller: "SettingsCtrl",
                        controllerAs: "vm"
                    }
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("global");
                            $translatePartialLoader.addPart("settings");
                            $translatePartialLoader.addPart("absenceType");
                            $translatePartialLoader.addPart("employee");
                            $translatePartialLoader.addPart("absence");
                            $translatePartialLoader.addPart("extra-holiday");
                            return $translate.refresh();
                        }
                    ],
                    entity: function() {
                        return {};
                    }
                }
            })
            .state("deletereason", {
                parent: "offtimesettings",
                url: "/delete-reason/{id}",
                data: {
                    authorities: ["ROLE_MANAGER"]
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/reasons/absence-reason-delete-dialog.html",
                                controller:
                                    "AbsenceReasonDeleteDialogController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "md",
                                resolve: {
                                    entity: [
                                        "AbsenceReasons",
                                        function(AbsenceReasons) {
                                            return AbsenceReasons.get({
                                                id: $stateParams.id
                                            }).$promise;
                                        }
                                    ]
                                }
                            })
                            .result.then(
                                function() {
                                    $state.go("offtimesettings", null, {
                                        reload: "offtimesettings"
                                    });
                                },
                                function() {
                                    $state.go("^");
                                }
                            );
                    }
                ]
            })
            .state("updatereason", {
                parent: "offtimesettings",
                url: "/update-reason/{id}",
                data: {
                    authorities: ["ROLE_MANAGER"]
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/reasons/absence-reason-update-dialog.html",
                                controller:
                                    "AbsenceReasonUpdateDialogController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "md",
                                resolve: {
                                    entity: [
                                        "AbsenceReasons",
                                        function(AbsenceReasons) {
                                            return AbsenceReasons.get({
                                                id: $stateParams.id
                                            }).$promise;
                                        }
                                    ]
                                }
                            })
                            .result.then(
                                function() {
                                    $state.go("offtimesettings", null, {
                                        reload: "offtimesettings"
                                    });
                                },
                                function() {
                                    $state.go("^");
                                }
                            );
                    }
                ]
            })
            .state("addVacationDays", {
                parent: "offtimesettings",
                url: "/vacationDays/",
                data: {
                    authorities: ["ROLE_MANAGER"]
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/employee/vacationdays/vacationdays-dialog.html",
                                controller: "VacationDaysDialogController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "lg"
                            })
                            .result.then(
                                function() {
                                    $state.go("offtimesettings", null, {
                                        reload: "offtimesettings"
                                    });
                                },
                                function() {
                                    $state.go("^");
                                }
                            );
                    }
                ]
            })
            .state("exportAbsences", {
                parent: "offtimesettings",
                params: { absencesToExport: null },
                url: "/export/",
                data: {
                    authorities: ["ROLE_MANAGER"]
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/absence/export/export-dialog.html",
                                controller: "ExportAbsencesDialogController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "md"
                            })
                            .result.then(
                                function() {
                                    $state.go("offtimesettings", null, {
                                        reload: "offtimesettings"
                                    });
                                },
                                function() {
                                    $state.go("^");
                                }
                            );
                    }
                ]
            });
    }
})();
