(function() {
    'use strict';
    angular
        .module('holidayApp')
        .factory('Company', Company);

    Company.$inject = ['$resource'];

    function Company ($resource) {
        var resourceUrl =  'api/company';

        return $resource(resourceUrl, {}, {
            'save': { method:'POST' }
        });
    }
})();
