(function() {
    'use strict';

    angular
        .module('holidayApp')
        .factory('UserUtils', UserUtils);

    UserUtils.$inject = [];

    function UserUtils() {

        var service = {
            preprocessLoginName: preprocessLoginName,
            replaceUmlaut: replaceUmlaut,
            replaceSpaces: replaceSpaces
        };

        return service;
        
        /**
         * This function sets the text to lowercase and replaces all the lowercase umlauts
         * because those are not valid for a username. It also replaces spaces to hyphens, because
         * spaces are not allowed either.
         * @param {String} text 
         */
        function preprocessLoginName(text) {

            // only process the preprocessing if there is something to process
            if (!text) {
                return "";
            }

            // we only use lowercase strings 
            var lowerCaseText = text.toLowerCase();

            // now replace the umlaut
            var umlautReplacedText = replaceUmlaut(lowerCaseText);

            // now only replace spaces
            var spaceReplacedText = replaceSpaces(umlautReplacedText);

            // return the preprocess login name
            return spaceReplacedText;
        }

        /**
         * This gets a text and a replacement value which is by default a hyphen
         * @param {String} text 
         * @param {String} replacement 
         */
        function replaceSpaces(text, replacement) {

            // only if there's a text
            if (!text) {
                return text;
            }

            // the default replacement is a hypen
            if (!replacement) {
                replacement = "-";
            }

            // now replace spaces with -
            var preprocessedText = text.replace(RegExp(' ', 'g'), replacement);

            // return the text
            return preprocessedText;
        }

        function replaceUmlaut(text) {

            // only replace umlaut if there's actually a text
            if (!text) {
                return text;
            }

            var preprocessedText = text;

            // define the umlaut replacements
            var umlautMap = {
                '\u00dc': 'Ue',
                '\u00fc': 'ue',
                '\u00c4': 'Ae',
                '\u00e4': 'ae',
                '\u00d6': 'Oe',
                '\u00f6': 'oe',
                '\u00df': 'ss',
            };

            // loop through the lower case umlauts
            for (var umlaut in umlautMap) {

                // get the replacement
                var umlautReplacement = umlautMap[umlaut];

                // now replace all occurences within the string
                preprocessedText = preprocessedText.replace(RegExp(umlaut, 'g'), umlautReplacement);
            }

            // return the text
            return preprocessedText;
        }
    }
})();
