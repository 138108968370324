(function() {
    "use strict";

    angular
        .module("holidayApp")
        .controller("DashboardController", DashboardController);

    DashboardController.$inject = [
        "$scope",
        "$filter",
        "entity",
        "Absence",
        "Employee",
        "Principal",
        "AbsenceReasons",
        "DateUtils",
        "BusinessDays"
    ];

    function DashboardController(
        $scope,
        $filter,
        entity,
        Absence,
        Employee,
        Principal,
        AbsenceReasons,
        DateUtils,
        BusinessDays
    ) {
        console.log(entity)
        var vm = this;
        vm.loaded = false;
        vm.isCollapsedNewRequest = false;
        vm.isCollapsedDetailRequest = false;

        vm.authorized = false;

        vm.absence = entity;

        vm.datePickerOpenStatus = {};
        vm.settingsAccount = null;

        vm.absencesForCurrUser = [];
        vm.acceptedForCurrUser = [];
        vm.requestsForCurrUser = [];
        vm.rejectedForCurrUser = [];
        vm.absencesForManager = [];
        vm.departmentCount = 0;
        vm.holiday = null;
        vm.employees = [];
        vm.currFilter = [];
        vm.filteredIds = [];

        $scope.dynamicPopover = {
            templateUrl: "myPopoverTemplate.html"
        };

        vm.currEmployee = null;
        vm.events = [];
        vm.event = {};
        vm.datesValid = false;

        vm.thisYear = new Date().getFullYear();
        vm.lastYear = new Date().getFullYear() - 1;
        vm.nextYear = new Date().getFullYear() + 1;
        vm.year = 1;

        vm.years = [vm.lastYear, vm.thisYear, vm.nextYear];
        vm.absenceCreateError = true;

        vm.next = next;
        vm.last = last;

        vm.loadCurrentUser = loadCurrentUser;

        vm.getCalendarDate = getCalendarDate;
        vm.onAbsenceClick = onAbsenceClick;

        vm.calendarDate = {};
        vm.calendarDateReport = {};

        vm.setInitials = setInitials;
        vm.getInitials = getInitials;
        vm.filterEmployees = filterEmployees;
        vm.resetEmployees = resetEmployees;
        vm.removeFilteredEmployee = removeFilteredEmployee;

        function getInitials(first, last) {
            if (first && last) {
                var initialFistName = first.substring(0, 1);
                var initialLastName = last.substring(0, 1);
                return initialFistName + initialLastName;
            }
        }

        function setInitials(first, last) {
            var initialFistName = first.substring(0, 1);
            var initialLastName = last.substring(0, 1);
            $("#initInitials").text(initialFistName + initialLastName);
        }

        function next(year) {
            if (year < 2) {
                vm.year += 1;
            }
        }

        function last(year) {
            if (year > 0) {
                vm.year -= 1;
            }
        }

        function onAbsenceClick(id) {
            if (id === vm.absenceDetailId && vm.isCollapsedDetailRequest) {
                vm.isCollapsedDetailRequest = false;
                vm.isCollapsedNewRequest = false;
            } else if (id > 0) {
                vm.absenceDetailId = id;
                vm.isCollapsedDetailRequest = true;
                vm.isCollapsedNewRequest = false;
            }
        }

        function getCalendarDate() {
            var count = 0;

            for (var k = 0; k < 3; k++) {
                var year = new Date().getFullYear() - 1 + k;
                vm.calendarDate[k] = [];
                vm.calendarDateReport[k] = [];
                for (var i = 1; i < 13; i++) {
                    var daysInMonth = new Date(year, i, 0).getDate();
                    vm.calendarDate[k][i] = [];
                    vm.calendarDateReport[k][i] = [];
                    for (var j = 1; j < daysInMonth + 1; j++) {
                        var date = new Date(year, i - 1, j);
                        var holiday = "";
                        var whichHoliday = "";
                        for (var z = 0; z < vm.holiday.length; z++) {
                            if (
                                date.toDateString() ==
                                new Date(vm.holiday[z].date).toDateString()
                            ) {
                                holiday = "feiertag";
                                whichHoliday = vm.holiday[z].description;
                            }
                        }
                        var abs = checkStatusAndId(date);

                        if (vm.authorized) {
                            var absForManager = checkStatusAndIdForManager(
                                date
                            );
                        }
                        var today = "";
                        var weekend = "";
                        if (
                            count == 0 &&
                            new Date().setHours(0, 0, 0, 0) ==
                                date.setHours(0, 0, 0, 0)
                        ) {
                            today = "today";
                            count++;
                        }
                        if (date.getDay() == 6 || date.getDay() == 0) {
                            weekend = "weekend";
                        }

                        vm.calendarDate[k][i][j] = {
                            day: j,
                            status: abs[0],
                            absenceid: abs[1],
                            today: today,
                            weekend: weekend,
                            holiday: holiday,
                            whichHoliday: whichHoliday
                        };

                        if (vm.authorized) {
                            vm.calendarDateReport[k][i][j] = {
                                day: j,
                                heat: absForManager[0],
                                absencesOnDay: absForManager[1],
                                today: today,
                                weekend: weekend,
                                holiday: holiday,
                                whichHoliday: whichHoliday
                            };
                        }
                    }
                }
            }

            vm.loaded = true;
        }

        function removeFilteredEmployee(id) {
            vm.currFilter = $.grep(vm.currFilter, function(e) {
                return e.id != id;
            });

            updateCalendar();
        }

        function filterEmployees(id, firstName, lastName) {
            var filterEmployee = {};

            var containsId = false;

            for (var i = 0; i < vm.currFilter.length; i++) {
                if (vm.currFilter[i].id == id) {
                    containsId = true;
                    break;
                }
            }

            if (!containsId) {
                filterEmployee.id = id;
                filterEmployee.firstName = firstName;
                filterEmployee.lastName = lastName;

                vm.currFilter.push(filterEmployee);
                updateCalendar();
            }
        }

        function updateCalendar() {
            vm.filteredIds = [];

            for (var k = 0; k < vm.currFilter.length; k++) {
                vm.filteredIds.push(vm.currFilter[k].id);
            }
            Absence.query({ holiday: 1 }, function(result) {
                vm.holiday = result;
                Absence.query({ employeeId: vm.currEmployee.id }, function(
                    result
                ) {
                    vm.absencesForCurrUser = result;
                    Absence.query({ filteredIds: vm.filteredIds }, function(
                        result
                    ) {
                        vm.absencesForManager = result;
                        getCalendarDate();
                    });
                });
            });
        }

        function resetEmployees() {
            vm.currFilter = [];
            Absence.query({ holiday: 1 }, function(result) {
                vm.holiday = result;
                Absence.query({ employeeId: vm.currEmployee.id }, function(
                    result
                ) {
                    vm.absencesForCurrUser = result;
                    Absence.query(
                        { departmentId: vm.currEmployee.department.id },
                        function(result) {
                            vm.absencesForManager = result;
                            getCalendarDate();
                        }
                    );
                });
            });
        }

        function checkStatusAndId(date) {
            date = new Date($filter("date")(date, "yyyy-MM-dd"));
            for (var i = 0; i < vm.absencesForCurrUser.length; i++) {
                var absenceFromDate = new Date(
                    vm.absencesForCurrUser[i].fromDate
                );
                var absenceToDate = new Date(vm.absencesForCurrUser[i].toDate);
                if (date >= absenceFromDate && date <= absenceToDate) {
                    var status = vm.absencesForCurrUser[i].approval.status;
                    if (status == "REJECTED") {
                        return ["REJECTED", vm.absencesForCurrUser[i].id];
                    }
                    var id = vm.absencesForCurrUser[i].id;
                    if (date.getTime() == absenceFromDate.getTime()) {
                        status += " start";
                    }
                    if (date.getTime() == absenceToDate.getTime()) {
                        status += " end";
                    }
                    var id = vm.absencesForCurrUser[i].id;
                    return [status, id];
                }
            }
            return ["none", "null"];
        }

        function checkStatusAndIdForManager(date) {
            var absencesOnDayCount = 0;
            var absences = [];
            var requested = "ACCEPTED";
            date = new Date($filter("date")(date, "yyyy-MM-dd"));
            for (var i = 0; i < vm.absencesForManager.length; i++) {
                var absenceFromDate = new Date(
                    vm.absencesForManager[i].fromDate
                );
                var absenceToDate = new Date(vm.absencesForManager[i].toDate);
                if (date >= absenceFromDate && date <= absenceToDate) {
                    var status = vm.absencesForManager[i].approval.status;
                    if (status == "REJECTED") {
                        return ["none", "null"];
                    }
                    if (status == "REQUESTED") {
                        requested = "REQUESTED";
                    }
                    absencesOnDayCount += 1;
                    absences.push(vm.absencesForManager[i]);
                }
            }

            var heat = absencesOnDayCount / vm.departmentCount;
            if (heat == 0) {
                return ["none", "null"];
            } else if (heat <= 1 / 3 && heat > 0) {
                return ["low " + requested, absences];
            } else if (heat >= 1 / 3 && heat <= 2 / 3) {
                return ["middle " + requested, absences];
            } else if (heat > 2 / 3) {
                return ["high " + requested, absences];
            } else {
                return ["none", "null"];
            }
        }

        Principal.identity().then(function(account) {
            vm.settingsAccount = copyAccount(account);
            vm.authorized = account.authorities.indexOf("ROLE_MANAGER") > -1;
            loadCurrentUser();
        });

        function loadCurrentUser() {
            $("#username").html(vm.settingsAccount.firstName);
            Employee.get({ login: vm.settingsAccount.login }, function(result) {
                vm.currEmployee = result;
                setInitials(
                    vm.currEmployee.user.firstName,
                    vm.currEmployee.user.lastName
                );
                loadAbsencesForList();
            });
        }

        function loadAbsencesForList() {
            Absence.query({ employeeId: vm.currEmployee.id }, function(result) {
                console.log("arrived vm")
                console.log(result)
                vm.requestsForCurrUser = $filter("filter")(result, {
                    requested: "true"
                });
                vm.rejectedForCurrUser = $filter("filter")(result, {
                    rejected: "true"
                });
            });

            Absence.query(
                { employeeId: vm.currEmployee.id, futureAbsences: true },
                function(result) {
                    vm.acceptedForCurrUser = result;
                }
            );

            Employee.query(
                { departmentId: vm.currEmployee.department.id },
                function(result) {
                    vm.departmentCount = result.length;
                    vm.employees = result;
                }
            );

            Absence.query({ holiday: 1 }, function(result) {
                vm.holiday = result;
                Absence.query({ employeeId: vm.currEmployee.id }, function(
                    result
                ) {
                    vm.absencesForCurrUser = result;
                    Absence.query(
                        { departmentId: vm.currEmployee.department.id },
                        function(result) {
                            vm.absencesForManager = result;
                            getCalendarDate();
                        }
                    );
                });
            });
        }

        /**
         * Store the "settings account" in a separate variable, and not in the shared "account" variable.
         */
        var copyAccount = function(account) {
            return {
                activated: account.activated,
                email: account.email,
                firstName: account.firstName,
                langKey: account.langKey,
                lastName: account.lastName,
                login: account.login
            };
        };
    }
})();
