(function () {
    'use strict';

    angular
        .module('holidayApp')
        .controller('RequestController', RequestController);


    RequestController.$inject = ['$scope', 'Absence', 'Employee', 'Principal', '$filter'];


    function RequestController($scope, Absence, Employee, Principal, $filter) {
        var vm = this;

        vm.loaded = false;

        vm.years = [
            new Date().getFullYear() - 1,
            new Date().getFullYear(),
            new Date().getFullYear() + 1
        ];

        vm.selectedYears = [ vm.years[0].toString(), vm.years[1].toString(), vm.years[2].toString()];

        vm.filterChanged = filterChanged;


        vm.queryUser = "";
        vm.settingsAccount = null;
        vm.currEmployee = null;
        vm.absences = [];
        vm.todayAbsent = [];
        vm.sick = [];
        vm.employees = [];

        vm.requestedTotalItems = 0;
        vm.maxSize = 15;
        vm.itemsPerPage = 6;

        vm.requestedCurrPage = 1;
        vm.requestedAbsences = [];
        vm.requestedPaginatedAbsences = [];

        vm.acceptedCurrPage = 1;
        vm.acceptedAbsences = [];
        vm.acceptedPaginatedAbsences = [];

        vm.rejectedCurrPage = 1;
        vm.rejectedAbsences = [];
        vm.rejectedPaginatedAbsences = [];



        $scope.$watch("vm.acceptedCurrPage", function() {
            if(angular.isDefined(vm.acceptedCurrPage) && angular.isDefined(vm.acceptedPaginatedAbsences)){
                vm.acceptedPaginatedAbsences = setPagingData(vm.acceptedCurrPage, vm.acceptedAbsences);
            }
        });

        $scope.$watch("vm.rejectedCurrPage", function() {
            if(angular.isDefined(vm.rejectedCurrPage) && angular.isDefined(vm.rejectedPaginatedAbsences)){
                vm.rejectedPaginatedAbsences = setPagingData(vm.rejectedCurrPage, vm.rejectedAbsences);
            }
        });


        $scope.$watch("vm.requestedCurrPage", function() {
            if(angular.isDefined(vm.requestedCurrPage) && angular.isDefined(vm.requestedPaginatedAbsences)){
                vm.requestedPaginatedAbsences = setPagingData(vm.requestedCurrPage, vm.requestedAbsences);
            }
        });

        function setPagingData(page, data) {
            var pagedData = data.slice(
                (page - 1) * vm.itemsPerPage,
                page * vm.itemsPerPage
            );
            //pagedData = $filter('orderBy')(vm.pagedData, {fromDate: 'true'});
            return $filter('orderBy')(pagedData, 'fromDate');
        }


        vm.loadAll = loadAll;
        vm.loadCurrentUser = loadCurrentUser;
        vm.onlyRequested = onlyRequested;
        vm.onlyAccepted = onlyAccepted;
        vm.onlyRejected = onlyRejected;
        vm.include = include;
        vm.openFilter = openFilter;
        vm.accFilter = accFilter;
        vm.rejFilter = rejFilter;
        vm.onAbsenceClick = onAbsenceClick;


        $scope.isCollapsedDetail = true;

        vm.getInitials = getInitials;


        function getInitials(firstName, lastName) {
            var initialFistName = firstName.substring(0, 1);
            var initialLastName = lastName.substring(0, 1);
            return (initialFistName + initialLastName);
        }


        Principal.identity().then(function (account) {
            vm.settingsAccount = copyAccount(account);
            loadCurrentUser();

        });

        function loadCurrentUser() {
            Employee.get({login: vm.settingsAccount.login}, function (result) {
                vm.currEmployee = result;
                loadAll();
            });

        }


        var copyAccount = function (account) {
            return {
                activated: account.activated,
                email: account.email,
                firstName: account.firstName,
                langKey: account.langKey,
                lastName: account.lastName,
                login: account.login
            };
        };


        function include(status, type) {
            var i = 0;
            if (status == "REQUESTED") {
                i = $.inArray(type, vm.openInclude);
                if (i > -1) {
                    vm.openInclude.splice(i, 1);
                } else {
                    vm.openInclude.push(type);
                }
            }

            if (status == "ACCEPTED") {
                i = $.inArray(type, vm.accInclude);
                if (i > -1) {
                    vm.accInclude.splice(i, 1);
                } else {
                    vm.accInclude.push(type);
                }
            }

            if (status == "REJECTED") {
                i = $.inArray(type, vm.rejInclude);
                if (i > -1) {
                    vm.rejInclude.splice(i, 1);
                } else {
                    vm.rejInclude.push(type);
                }
            }

        }


        function openFilter(absence) {
            if ($.inArray(absence.type, vm.openInclude) < 0) {
                return;
            }
            return vm.absences;
        }

        function accFilter(absence) {
            if ($.inArray(absence.type, vm.accInclude) < 0) {
                return;
            }
            return vm.absences;
        }

        function rejFilter(absence) {
            if ($.inArray(absence.type, vm.rejInclude) < 0) {
                return;
            }
            return vm.absences;
        }

        function onlyRequested(absence) {
            if ($.inArray(absence.approval.status, ["REQUESTED"]) < 0) {
                return;
            }
            return vm.absences;
        }

        function onlyRejected(absence) {
            if ($.inArray(absence.approval.status, ["REJECTED"]) < 0) {
                return;
            }
            return vm.absences;
        }

        function onlyAccepted(absence) {
            if ($.inArray(absence.approval.status, ["ACCEPTED"]) < 0) {
                return;
            }
            return vm.absences;
        }


        function getTodayAbsentAndSickEmployees() {
            var todaysDate = Date.now();
            for (var i = 0; i < vm.absences.length; i++) {
                if (vm.absences[i].approval.status == "ACCEPTED" &&
                    new Date(vm.absences[i].fromDate) <= todaysDate &&
                    new Date(vm.absences[i].toDate) >= todaysDate) {
                    vm.todayAbsent.push(vm.absences[i]);
                    if (vm.absences[i].type == "SICKNESS") {
                        vm.sick.push(vm.absences[i]);
                    }
                }
            }
            vm.loaded = true;
        }

        function onAbsenceClick(id) {
            if (id > -1) {
                Absence.get({id: id}, function (result) {
                    vm.absenceDetails = result;
                });
                $scope.isCollapsedDetail = false;
            }
        }

        function loadAll() {
            Absence.query({departmentId: vm.currEmployee.department.id}, function (result) {
                vm.absences = result;
                vm.absences = $filter('orderBy')(vm.absences, 'fromDate');
                getOnlyRequested();
                getOnlyAccepted();
                getOnlyRejected();

                getTodayAbsentAndSickEmployees();
            });

            Employee.query({departmentId: vm.currEmployee.department.id}, function (result) {
                vm.employees = result;
            });

        }

        function getOnlyRejected(){
            vm.rejectedAbsences =  $filter('filter')(vm.absences, {rejected: 'true'});
            vm.rejectedAbsences = $filter('filterRequests')(vm.rejectedAbsences, vm.queryUser, vm.selectedYears);
            vm.rejectedTotalItems = vm.rejectedAbsences.length;
            vm.rejectedCurrPage = 1;
            vm.rejectedPaginatedAbsences = setPagingData(vm.rejectedCurrPage, vm.rejectedAbsences);
        }

        function getOnlyAccepted(){
            vm.acceptedAbsences =  $filter('filter')(vm.absences, {accepted: 'true'});
            vm.acceptedAbsences = $filter('filterRequests')(vm.acceptedAbsences, vm.queryUser, vm.selectedYears);
            vm.acceptedTotalItems = vm.acceptedAbsences.length;
            vm.acceptedCurrPage = 1;
            vm.acceptedPaginatedAbsences = setPagingData(vm.acceptedCurrPage, vm.acceptedAbsences);
        }

        function getOnlyRequested(){
            vm.requestedAbsences =  $filter('filter')(vm.absences, {requested: 'true'});
            vm.requestedAbsences = $filter('filterRequests')(vm.requestedAbsences, vm.queryUser, vm.selectedYears);
            vm.requestedTotalItems = vm.requestedAbsences.length;
            vm.requestedCurrPage = 1;
            vm.requestedPaginatedAbsences = setPagingData(vm.requestedCurrPage, vm.requestedAbsences);
        }


        function filterChanged(){
            getOnlyRequested();
            getOnlyAccepted();
            getOnlyRejected();
        }

    }
})();
