(function () {
    'use strict';

    angular
        .module('holidayApp')
        .controller('AbsenceReasonDeleteDialogController', AbsenceReasonDeleteDialogController);

    AbsenceReasonDeleteDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'AbsenceReasons'];

    function AbsenceReasonDeleteDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, AbsenceReasons) {
        var vm = this;

        vm.reason = entity;
        vm.clear = clear;
        vm.deleteReason = deleteReason;


        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function deleteReason(id){
            AbsenceReasons.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }

    }
})();
