(function () {
    'use strict';


    angular
        .module('holidayApp')
        .controller('CreateAbsenceController', CreateAbsenceController);


    CreateAbsenceController.$inject = ['$scope', '$parse', '$state', '$attrs', 'DateUtils', 'AbsenceReasons', 'BusinessDays', 'Absence'];


    function CreateAbsenceController($scope, $parse, $state, $attrs, DateUtils, AbsenceReasons, BusinessDays, Absence) {
        var vm = this;
        vm.isCollapsedNewRequest = true;
        vm.isSaving = false;
        vm.currEmployee = {};
        vm.requestedDays = null;
        vm.requestedDaysNextYear = null;

        $attrs.$observe('requestPanelOpen', function (value) {
            if (vm.isCollapsedNewRequest) {
                vm.isCollapsedNewRequest =
                    (value === "true");
            } else {
                vm.isCollapsedNewRequest = true;
            }
        });

        $attrs.$observe('currentEmployee', function (value) {
            if (value) {
                vm.currEmployee = JSON.parse(value);
            }

        });

        vm.openOrCloseDatePicker = openOrCloseDatePicker;
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.checkDates = checkDates;
        vm.enoughDays = enoughDays;
        vm.close = close;
        vm.noOfftimeRequired = noOfftimeRequired;


        vm.datePickerOpenStatus = {fromDate: false, toDate: false};
        vm.datesValid = false;
        vm.absence = {};
        vm.absence.employee = {};
        vm.absence.absenceReason = [];
        vm.reasons = [];


        init();


        function init() {
            AbsenceReasons.query({}, function (result) {
                vm.reasons = result;
                vm.absence.absenceReason = vm.reasons[0];
            });
        }

        function setBusinessDaysThisYear(data) {
            vm.requestedDays = data.duration < 0 ? null : data.duration;
        }

        function setBusinessDaysNextYear(data) {
            vm.requestedDaysNextYear = data.duration < 0 ? null : data.duration;
        }


        function checkDates(startDate, endDate) {
            

            var curDate = new Date();
            if (startDate > endDate || startDate <= curDate) {
                vm.datesValid = false;
            } else {
                vm.datesValid = true;
            }


            if(startDate && !endDate || startDate > endDate){
                vm.absence.toDate = startDate;
                endDate = startDate;
            }

            if (startDate && endDate) {

                const endYear = endDate.getFullYear()
                const startYear = startDate.getFullYear()

                var from = DateUtils.convertLocalDateToServer(startDate);
                var to = DateUtils.convertLocalDateToServer(endDate);

                if (endYear === startYear + 1 && startYear === curDate.getFullYear()) {
                    const endThisYear = new Date(startYear, 11, 31)
                    const startNextYear = new Date(startYear, 11, 31)

                    const fromNext = DateUtils.convertLocalDateToServer(startNextYear);
                    const toThis = DateUtils.convertLocalDateToServer(endThisYear);

                    BusinessDays.getRequestedDays(
                        {fromDate: from, toDate: toThis}, 
                        function (data) {setBusinessDaysThisYear(data);}
                        );

                    BusinessDays.getRequestedDays(
                        {fromDate: fromNext, toDate: to},
                        function (data) {setBusinessDaysNextYear(data); }
                        );

                } else if (endYear === startYear) {
                    if (startYear === curDate.getFullYear()) {
                        BusinessDays.getRequestedDays(
                            {fromDate: from, toDate: to}, 
                            function (data) { setBusinessDaysThisYear(data);  }
                        );
    
                        const dataNextYear = { duration: 0 };
                        setBusinessDaysNextYear(dataNextYear);
                    } else if (startYear === curDate.getFullYear()+1) {
                        BusinessDays.getRequestedDays(
                            {fromDate: from, toDate: to}, 
                            function (data) { setBusinessDaysNextYear(data);  }
                        );
    
                        const dataThisYear = { duration: 0 };
                        setBusinessDaysThisYear(dataThisYear);

                    }


                } else {
                    vm.datesValid = false;
                }

            }

        }


        function openOrCloseDatePicker(date) {
            if (!vm.datePickerOpenStatus[date]) {
                openCalendar(date);
            } else {
                closeCalendar(date);
            }
        }


        function closeCalendar(date) {
            vm.datePickerOpenStatus[date] = false;
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }


        function save() {
            vm.isSaving = true;
            if (vm.absence.id) {
                Absence.update(vm.absence, onSaveSuccess, onSaveError);
            } else {
                vm.absence.employee = vm.currEmployee;
                Absence.save(vm.absence, onSaveSuccess, onSaveError);
            }
        }


        function onSaveSuccess(result) {
            resetForm();
            vm.isSaving = false;
            $state.reload();
        }

        function resetForm() {
            vm.absence.fromDate = null;
            vm.absence.toDate = null;
            vm.absence.absenceReason = vm.reasons[0];
            vm.absence.comment = null;
            vm.absence.employee = null;
            vm.requestedDays = null;
            vm.requestedDaysNextYear = null;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function close(){
           resetForm();
            vm.isCollapsedNewRequest = !vm.isCollapsedNewRequest
        }


        function enoughDays() {
            if (vm.requestedDays !== null && vm.requestedDaysNextYear !== null) {
                const daysLastYear = vm.currEmployee.availableVacationDaysThisYear + vm.currEmployee.unusedVacationDaysLastYear
                const hasEnoughDaysThisYear = daysLastYear >= vm.requestedDays
                const hasEnoughDaysNextYear = daysLastYear + vm.currEmployee.vacationDaysPerYear - vm.requestedDaysNextYear - vm.currEmployee.usedVacationDaysNextYear >= vm.requestedDaysNextYear
                return hasEnoughDaysNextYear && hasEnoughDaysThisYear
                }
        }
    
        function noOfftimeRequired(){
            if((!vm.requestedDays && vm.absence.fromDate && vm.absence.toDate) || !vm.absence.absenceReason.needsVacationDays){
                return true;
            }else{
                return false;
            }
        }

    }
})();
