(function() {
    'use strict';

    angular
        .module('holidayApp')
        .directive('scrollTo', scrollTo);

    function scrollTo() {
        var directive = {
            restrict: 'A',
            link: function($scope, element){
                

                $('.timeline').animate({scrollLeft : 630}, 500);


                $('.to-middle').on('click', function(){
                    $('.timeline').animate({scrollLeft : 630}, 500);

                });

                $('.to-left').on('click', function() {
                    $('.timeline').animate({scrollLeft : $(".timeline").scrollLeft()-630}, 500);

                });

                $('.to-right').on('click', function() {
                    $('.timeline').animate({scrollLeft : $(".timeline").scrollLeft()+630}, 500);
                });



            }
        };


        return directive;

    }
})();
