(function() {
    'use strict';

    angular
        .module('holidayApp')
        .filter('isUpcoming', function() {
            return function(items) {
                return items.filter(function(item){
                    return moment(item.fromDate).isAfter(new Date());
                })
            }
        })
})();
