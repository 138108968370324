(function() {
    "use strict";
    angular.module("holidayApp").factory("Absence", Absence);

    Absence.$inject = ["$resource", "DateUtils"];

    function Absence($resource, DateUtils) {
        var resourceUrl = "api/absences/:id";

        return $resource(
            resourceUrl,
            {},
            {
                query: {
                    method: "GET",
                    params: {
                        period: "@period",
                        departmentId: "@departmentId",
                        employeeId: "@employeeId",
                        only: "@only",
                        holiday: "@holiday",
                        filteredIds: "@filteredIds",
                        absencesForCsv: "@absencesForCsv",
                        year: "@year",
                        futureAbsences: "@futureAbsences"
                    },
                    isArray: true
                },
                get: {
                    method: "GET",
                    transformResponse: function(data) {
                        if (data) {
                            data = angular.fromJson(data);
                            data.fromDate = DateUtils.convertLocalDateFromServer(
                                data.fromDate
                            );
                            data.toDate = DateUtils.convertLocalDateFromServer(
                                data.toDate
                            );
                        }
                        return data;
                    }
                },
                update: {
                    method: "PUT",
                    transformRequest: function(data) {
                        var copy = angular.copy(data);
                        copy.fromDate = DateUtils.convertLocalDateToServer(
                            copy.fromDate
                        );
                        copy.toDate = DateUtils.convertLocalDateToServer(
                            copy.toDate
                        );
                        return angular.toJson(copy);
                    }
                },
                save: {
                    method: "POST",
                    transformRequest: function(data) {
                        var copy = angular.copy(data);
                        copy.fromDate = DateUtils.convertLocalDateToServer(
                            copy.fromDate
                        );
                        copy.toDate = DateUtils.convertLocalDateToServer(
                            copy.toDate
                        );
                        return angular.toJson(copy);
                    }
                }
            }
        );
    }
})();
