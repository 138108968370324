(function() {
    "use strict";
    angular.module("holidayApp").factory("Employee", Employee);

    Employee.$inject = ["$resource"];

    function Employee($resource) {
        var resourceUrl = "api/employees/:id";

        return $resource(
            resourceUrl,
            {},
            {
                query: {
                    method: "GET",
                    params: { department: "@departmentId" },
                    isArray: true
                },
                get: {
                    method: "GET",
                    params: { login: "@login" },
                    transformResponse: function(data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                },
                save: { method: "POST" },
                update: { method: "PUT" },
                delete: { method: "DELETE" }
            }
        );
    }
})();
