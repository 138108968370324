(function() {
    "use strict";

    angular.module("holidayApp").controller("SettingsCtrl", SettingsCtrl);

    SettingsCtrl.$inject = [
        "$scope",
        "Principal",
        "$translate",
        "$filter",
        "$timeout",
        "$state",
        "AbsenceReasons",
        "Absence",
        "DateUtils",
        "BusinessDays",
        "Employee",
        "ExtraHoliday"
    ];

    function SettingsCtrl(
        $scope,
        Principal,
        $translate,
        $filter,
        $timeout,
        $state,
        AbsenceReasons,
        Absence,
        DateUtils,
        BusinessDays,
        Employee,
        ExtraHoliday
    ) {
        var vm = this;

        vm.isCollapsedNewRequest = true;
        vm.closeAbsenceCreation = closeAbsenceCreation;

        vm.isCollapsedExtraHoliday = true;
        vm.extraHolidays = [];

        vm.isCollapsedReason = true;

        vm.datePickerOpenStatus = {};

        vm.successMessage = null;

        vm.absence = {};
        vm.absence.absenceReason = [];
        vm.reasons = [];
        vm.absence.asAdmin = true;
        vm.employees = [];
        vm.exportEmployees = [];
        vm.currYear = new Date().getFullYear();
        vm.inputYear = new Date().getFullYear();
        vm.employeeIds = [];
        vm.allAbsencesForExport = [];
        vm.employeeAbsencesForExport = [];
        vm.yearAbsencesForExport = [];
        vm.datesValid = false;

        vm.absenceReasons = [];
        vm.settingsAccount = null;
        vm.authorized = null;
        vm.reason = { icon: "fa-plane", name: null, needsVacationDays: true };
        vm.saveReason = saveReason;
        vm.checkAndOpenOrClose = checkAndOpenOrClose;
        vm.openCalendar = openCalendar;
        vm.saveAbsence = saveAbsence;
        vm.enoughDays = enoughDays;
        vm.checkDates = checkDates;
        vm.calcMinusDays = calcMinusDays;
        vm.calcMinusDaysNextYear = calcMinusDaysNextYear;
        vm.validYear = validYear;
        vm.allAbsencesForCsv = null;
        vm.yearAbsencesForCsv = null;
        vm.openExportModal = openExportModal;
        vm.getAbsencesForCsv = getAbsencesForCsv;
        vm.noOfftimeRequired = noOfftimeRequired;

        vm.closeHoliday = closeHoliday;
        vm.saveHoliday = saveHoliday;
        vm.closeReason = closeReason;
        vm.openReason = openReason;

        vm.requestedDays = null;
        vm.requestedDaysNextYear = null;
        $scope.isCollapsedNewRequest = true;

        $("#pick-icons").iconpicker({
            icons: [
                "fa-thermometer-half",
                "fa-asterisk",
                "fa-bicycle",
                "fa-calendar",
                "fa-car",
                "fa-coffee",
                "fa-child",
                "fa-compass",
                "fa-female",
                "fa-globe",
                "fa-hotel",
                "fa-heart",
                "fa-power-off",
                "fa-question",
                "fa-snowflake-o",
                "fa-suitcase",
                "fa-university",
                "fa-wheelchair",
                "fa-rocket",
                "fa-plane",
                "fa-ambulance",
                "fa-train",
                "fa-truck",
                "fa-stethoscope",
                "fa-hospital-o",
                "fa-medkit",
                "fa-user-md",
                "fa-warning"
            ],
            templates: {
                iconpickerItem:
                    '<a role="button" href="#" class="iconpicker-item" title=""><i></i></a>'
            }
        });
        $(".iconpicker-item").removeAttr("title");

        /**
         * Store the "settings account" in a separate variable, and not in the shared "account" variable.
         */
        var copyAccount = function(account) {
            return {
                activated: account.activated,
                email: account.email,
                firstName: account.firstName,
                langKey: account.langKey,
                lastName: account.lastName,
                login: account.login
            };
        };

        Principal.identity().then(function(account) {
            vm.settingsAccount = copyAccount(account);
            vm.authorized = account.authorities.indexOf("ROLE_MANAGER") > -1;
            getAbsenceReasons();
            getEmployees();
        });

        function getEmployees() {
            Employee.query(function(result) {
                vm.employees = result;
            });
        }

        function checkAndOpenOrClose(date) {
            if (!vm.datePickerOpenStatus[date]) {
                openCalendar(date);
            } else {
                closeCalendar(date);
            }
        }

        function openExportModal(exp) {
            if (exp === "all") {
                exportAllAbsences();
            } else if (exp === "employees") {
                exportEmployeeAbsences();
            } else if (exp === "year") {
                exportYearAbsences();
            }
        }

        function exportAllAbsences() {
            var allEmployeeIds = [];
            angular.forEach(vm.employees, function(item) {
                allEmployeeIds.push(item.id);
            });

            Absence.query({ absencesForCsv: allEmployeeIds }, function(result) {
                $state.go("exportAbsences", { absencesToExport: result });
            });
        }

        function exportEmployeeAbsences() {
            vm.employeeIds = [];
            angular.forEach(vm.exportEmployees, function(item) {
                if (item !== "") vm.employeeIds.push(parseInt(item));
            });

            Absence.query({ absencesForCsv: vm.employeeIds }, function(result) {
                $state.go("exportAbsences", { absencesToExport: result });
            });
        }

        function exportYearAbsences() {
            vm.employeeIds = [];
            angular.forEach(vm.exportEmployees, function(item) {
                if (item !== "") vm.employeeIds.push(item);
            });

            Absence.query(
                { absencesForCsv: vm.employeeIds, year: validYear() },
                function(result) {
                    $state.go("exportAbsences", { absencesToExport: result });
                }
            );
        }

        function getAbsencesForCsv() {
            vm.employeeIds = [];
            angular.forEach(vm.exportEmployees, function(item) {
                if (item !== "") vm.employeeIds.push(parseInt(item));
            });
        }

        vm.datePickerOpenStatus.fromDate = false;
        vm.datePickerOpenStatus.toDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function closeCalendar(date) {
            vm.datePickerOpenStatus[date] = false;
        }

        function saveAbsence() {
            vm.absence.asAdmin = true;
            Absence.update(vm.absence, onAbsenceSaved, onAbsenceSaveError);
        }

        function closeAbsenceCreation() {
            vm.isCollapsedNewRequest = !vm.isCollapsedNewRequest;
            resetForm();
        }

        vm.pickDate = false;

        function closeHoliday() {
            vm.isCollapsedExtraHoliday = !vm.isCollapsedExtraHoliday;
            ExtraHoliday.query({}, function(result) {
                vm.extraHolidays = result;
            });
        }

        function saveHoliday() {
            ExtraHoliday.update(vm.extraHolidays);
            vm.isCollapsedExtraHoliday = !vm.isCollapsedExtraHoliday;
        }

        function onAbsenceSaved(result) {
            generateSuccessMessage(result);
            resetForm();
            vm.isCollapsedNewRequest = !vm.isCollapsedNewRequest;
            vm.isSaving = false;
        }

        function generateSuccessMessage(result) {
            vm.successMessage = {
                msg: "offtime-settings.absenceCreatedForUser",
                user: result.employee.user.fullName,
                from: new Date(result.fromDate).toLocaleDateString(),
                to: new Date(result.toDate).toLocaleDateString()
            };

            $timeout(function() {
                vm.successMessage = null;
            }, 5000);
        }

        function onAbsenceSaveError() {
            vm.isSaving = false;
        }

        function resetForm() {
            vm.absence.fromDate = null;
            vm.absence.toDate = null;
            vm.absence.absenceReason = vm.reasons[0];
            vm.absence.comment = null;
            vm.absence.employee = null;
            vm.requestedDays = null;
            vm.requestedDaysNextYear = null;
        }

        function checkDates(startDate, endDate) {
            

            var curDate = new Date();
            if (startDate > endDate || startDate <= curDate) {
                vm.datesValid = false;
            } else {
                vm.datesValid = true;
            }


            if(startDate && !endDate || startDate > endDate){
                vm.absence.toDate = startDate;
                endDate = startDate;
            }

            if (startDate && endDate) {

                const endYear = endDate.getFullYear()
                const startYear = startDate.getFullYear()

                var from = DateUtils.convertLocalDateToServer(startDate);
                var to = DateUtils.convertLocalDateToServer(endDate);

                if (endYear === startYear + 1) {
                    const endThisYear = new Date(startYear, 11, 31)
                    const startNextYear = new Date(startYear, 11, 31)

                    const fromNext = DateUtils.convertLocalDateToServer(startNextYear);
                    const toThis = DateUtils.convertLocalDateToServer(endThisYear);

                    BusinessDays.getRequestedDays(
                        {fromDate: from, toDate: toThis}, 
                        function (data) {setBusinessDaysThisYear(data);}
                        );

                    BusinessDays.getRequestedDays(
                        {fromDate: fromNext, toDate: to},
                        function (data) {setBusinessDaysNextYear(data); }
                        );

                } else if (endYear === startYear) {
                    BusinessDays.getRequestedDays(
                        {fromDate: from, toDate: to}, 
                        function (data) { setBusinessDaysThisYear(data);  }
                    );

                    const dataNextYear = { duration: 0 };
                    setBusinessDaysNextYear(dataNextYear);
                } else {
                    vm.datesValid = false;
                }

            }

        }

        function enoughDays() {
            if (vm.requestedDays !== null && vm.requestedDaysNextYear !== null) {
                const daysLastYear = vm.absence.employee.availableVacationDaysThisYear + vm.absence.employee.unusedVacationDaysLastYear
                const hasEnoughDaysThisYear = daysLastYear >= vm.requestedDays
                const hasEnoughDaysNextYear = daysLastYear + vm.absence.employee.vacationDaysPerYear - vm.requestedDays - vm.absence.employee.usedVacationDaysNextYear >= vm.requestedDaysNextYear
                return hasEnoughDaysNextYear && hasEnoughDaysThisYear
                }
        }
    

        function noOfftimeRequired() {
            if (
                (!vm.requestedDays &&
                    vm.absence.fromDate &&
                    vm.absence.toDate) ||
                !vm.absence.absenceReason.needsVacationDays
            ) {
                return true;
            } else {
                return false;
            }
        }


        function setBusinessDaysThisYear(data) {
            console.log(data)
            vm.requestedDays = data.duration < 0 ? null : data.duration;
        }

        function setBusinessDaysNextYear(data) {
            vm.requestedDaysNextYear = data.duration < 0 ? null : data.duration;
        }

        function calcMinusDays() {
            var available =
                vm.absence.employee.availableVacationDaysThisYear +
                vm.absence.employee.unusedVacationDaysLastYear;

            var wanted = vm.requestedDays;

            return Math.abs(available - wanted);
        }

        function calcMinusDaysNextYear() {
            const available = vm.absence.employee.vacationDaysPerYear + vm.absence.employee.availableVacationDaysThisYear + vm.absence.employee.unusedVacationDaysLastYear - vm.requestedDays - vm.absence.employee.usedVacationDaysNextYear
            const wanted = vm.requestedDaysNextYear
            
          
            return Math.abs(available - wanted);
        }

        function validYear() {
            if (vm.inputYear) {
                return vm.inputYear;
            } else {
                return vm.currYear;
            }
        }

        function closeReason() {
            vm.isCollapsedReason = !vm.isCollapsedReason;
            resetReason();
        }

        function resetReason() {
            vm.reason.id = null;
            vm.reason.icon = "fa-plane";
            vm.reason.needsVacationDays = true;
            vm.reason.name = null;
        }

        function saveReason() {
            vm.isSaving = true;

            var icon = document.getElementById("selected-icon");
            vm.reason.icon = icon.getAttribute("class").split(" ")[1];
            AbsenceReasons.save(vm.reason, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            getAbsenceReasons();
            closeReason();
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function getAbsenceReasons() {
            AbsenceReasons.query({}, function(result) {
                vm.absenceReasons = result;
                vm.reasons = result;
                vm.absence.absenceReason = vm.reasons[0];
            });
        }

        function openReason(id) {
            if (id) {
                AbsenceReasons.get(id, function(result) {
                    vm.reason = result;
                });
            }
            closeReason();
        }
    }
})();
